import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { $api } from "services/api";

export const getMyOrders = createAsyncThunk("orders/me", async () => {
  const response = await $api.getMyOrder();
  return response.data;
});

const initialState = {
  items: [],
};

export const myOrderSlice = createSlice({
  name: "myOrders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyOrders.pending, (state, action) => {})
      .addCase(getMyOrders.fulfilled, (state, action) => {
        state.items = action.payload;
      })
      .addCase(getMyOrders.rejected, (state, action) => {});
  },
});

export const { reducer: myOrdersReducer, actions: myOrdersActions } =
  myOrderSlice;
