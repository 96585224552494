import React from "react";
import "./promo.scss";
import Countdown from "react-countdown";

export function Promo({ children }) {
  return (
    <div className="promo">
      <div className="promo__title-container">
        <h1 className="promo__title">
          {children}
          <br />
        </h1>
        <h2 className="promo__subtitle">
          До закрытия магазина
          <br />
          <Countdown
            className="promo__title"
            date={new Date("Augst 17, 2023 14:00:00")}
          />
        </h2>
      </div>
    </div>
  );
}
