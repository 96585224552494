import { Promo } from "components/promo";
import React from "react";


export function NotFoundPage() {
  return (
    <>
      <Promo>
        Ooops! <br />
        404
        <br /> страница не найдена
      </Promo>
    </>
  );
}
