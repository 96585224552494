import Order from "components/order/order";
import React from "react";
import { useSelector } from "react-redux";
import "./order-page.scss";

export function OrderPage() {
  const user = useSelector((state) => state.user.data);
  const orders = useSelector((state) => state.orders.items);
  const myOrders = useSelector((state) => state.myOrder.items);

  return (
    <div className="orders container">
      <div className="orders__title-container">
        <h1>Заказы</h1>
        {/* <input type="checkbox" /> */}
      </div>
      <div className="orders__list">
        {user !== null &&
          user.is_admin &&
          orders.map((order, index) => <Order {...order} key={index} />)}
        {user !== null &&
          !user.is_admin &&
          myOrders.map((order, index) => <Order {...order} key={index} />)}
      </div>
    </div>
  );
}
